import { mergeProps, createSignal } from 'solid-js';
import { PersistentClient } from '~/shared/PersistentClient.ts';
import { CoreBand } from '~/shared/CoreBand';
import { CoreImage } from '~/shared/CoreImage';
import { CoreImageOperation } from '~/shared/CoreImageOperation';
import { CoreUser } from '~/shared/CoreUser';

export class DbTableProps {
  name: string;
  local: string;
  select: string;
  where: string;
  orderBy: string;
  join: string;
  limit: string;
  offset: string;
  table: string;
}

const defaultDbTableProps = {
  name: 'image',
  local: 'true',
  select: '', // default '' is to SELECT *
  where: '',
  orderBy: '',
  join: '',
  limit: '',
  offset: '',
  table: '',
} as DbTableProps;

export class DynamicDbTable {
  sigGetItems: Function;
  sigSetItems: Function;
  sigGetColumns: Function;
  sigSetColumns: Function;
  dbTableProps: DbTableProps;
  items: object[] = [
    { data: { index: 1, name: '' }, src: '' },
    { data: { index: 2, name: 'fetching data ...' }, src: '' },
  ];
  columns: string[] = ['index', 'name'];

  constructor(props: DbTableProps = {}) {
    this.dbTableProps = mergeProps(defaultDbTableProps, props);
    [this.sigGetItems, this.sigSetItems] = createSignal(this.items);
    [this.sigGetColumns, this.sigSetColumns] = createSignal(this.columns);
    this.populate();
  }

  public sigGetName(): string {
    return (
      this.dbTableProps.name +
      ' ' +
      (this.dbTableProps.local === 'true'
        ? ':local'
        : ':server[' + PersistentClient.wsDatabaseAPIURL + ']')
    );
  }

  public static tableName(folderName: string): string {
    const makeCamelCase = (str) =>
      str
        .split(/[- _]/)
        .map((e, i) =>
          i
            ? e.charAt(0).toUpperCase() + e.slice(1).toLowerCase()
            : e.toLowerCase()
        )
        .join('');
    return makeCamelCase('ws ' + folderName);
  }

  public async populate() {
    const persistentClient = PersistentClient.instance;
    if (persistentClient) {
      // only client side
      let keys = null;
      let values = null;
      const isLocal = this.dbTableProps.local === 'true';

      switch (this.dbTableProps.name) {
        case 'image':
          {
            keys = CoreImage.columns();
            values = await persistentClient.findImages(isLocal);
            if (values) {
              for (const row of values) {
                const coreImage = new CoreImage(row);
                let src = '';
                let thumb = coreImage.data.thumb_dataurl;
                row.src = src;
                row.thumb = thumb;
              }
            }
          }
          break;

        case 'image_operation':
          {
            keys = CoreImageOperation.columns();
            values = await persistentClient.findImageOperations(isLocal);
          }
          break;

        case 'user':
          {
            keys = CoreUser.columns();
            values = await persistentClient.findUsers(isLocal);
          }
          break;

        case 'band':
          {
            keys = CoreBand.columns();
            values = await persistentClient.findBands(isLocal);
          }
          break;

        default:
          {
            if (
              !this.dbTableProps.table ||
              this.dbTableProps.table.length === 0
            ) {
              this.dbTableProps.table = this.dbTableProps.name;
            }

            if (isLocal) {
              const sql =
                'SELECT ' +
                (this.dbTableProps.select.length > 0
                  ? this.dbTableProps.select
                  : '*') +
                ' FROM ' +
                DynamicDbTable.tableName(this.dbTableProps.table) +
                ' ' +
                (this.dbTableProps.join.length > 0
                  ? ' ' + this.dbTableProps.join
                  : '') +
                (this.dbTableProps.where.length > 0
                  ? ' WHERE ' + this.dbTableProps.where
                  : '') +
                (this.dbTableProps.orderBy.length > 0
                  ? ' ORDER BY ' + this.dbTableProps.orderBy
                  : '') +
                (this.dbTableProps.limit.length > 0
                  ? ' LIMIT ' + this.dbTableProps.limit
                  : '') +
                (this.dbTableProps.offset.length > 0
                  ? ' OFFSET ' + this.dbTableProps.offset
                  : '');
              values = await persistentClient.fetchLocal(sql);
            } else {
              // server
              const jsonFind = await persistentClient.fetchServer(
                this.dbTableProps.table,
                {
                  s: this.dbTableProps.select,
                  w: this.dbTableProps.where,
                  o: this.dbTableProps.orderBy,
                  l: this.dbTableProps.limit,
                  f: this.dbTableProps.offset,
                }
              );

              if (jsonFind.success) {
                values = jsonFind.results;
              } else {
                values = [{ error: jsonFind.error }, { error: jsonFind.sql }];
              }
            }

            if (values && values.length > 0) {
              keys = Object.keys(values[0]);
            }
          }
          break;
      }

      if (values) {
        if (keys && keys.length > 0) {
          this.sigSetColumns(keys);
        }
        this.sigSetItems(values);
      }
    }
  }
}

import type { JSX, Component } from 'solid-js';
import { For } from 'solid-js';
import {
  DbTableProps,
  DynamicDbTable,
} from '~/components/designer2/DynamicDbTable';

type Component<P = { object }> = (props: P) => JSX.Element;

function itemExists(item: object, index: number): boolean {
  return (
    Object.values(Object.hasOwn(item, 'data') ? item.data : item).length > index
  );
}
function itemValue(item: object, index: number): string {
  const value = Object.values(Object.hasOwn(item, 'data') ? item.data : item)[
    index
  ];
  return value && value.length > 80
    ? value.substring(0, 80) + '...'
    : value
      ? value
      : '';
}

export const DbTableComponent: Component<DbTableProps> = (props) => {
  const dbTable = new DynamicDbTable(props);
  return (
    // *INDENT-OFF*
    <div class="w-full h-1/2 overflow-x-auto overflow-y-auto">
      <span>{dbTable.sigGetName()}</span>
      <table class="border-black border-[3px]">
        <thead>
          <tr class="border-black border-[1px]">
            <th></th>
            <th></th>
            <For each={dbTable.sigGetColumns()}>
              {(item) => (
                <th class="border-black border-[1px]" style="position: sticky;">
                  {item}
                </th>
              )}
            </For>
          </tr>
        </thead>
        <tbody>
          <For each={dbTable.sigGetItems()}>
            {(item, index) => (
              <tr
                class={
                  'border-black border-[1px] text-gray-800 ' +
                  (index() % 2 === 0 ? 'bg-wearsharelime' : 'bg-wearsharepink')
                }
              >
                <td>
                  <Show
                    when={Object.hasOwn(item, 'thumb') && item.thumb.length > 0}
                  >
                    <a href={item.thumb} target="_image" title={item.thumb}>
                      thumb
                      <img
                        height="64"
                        crossOrigin="anonymous"
                        src={item.thumb}
                      />
                    </a>
                  </Show>
                </td>
                <td>
                  <Show
                    when={Object.hasOwn(item, 'src') && item.src.length > 0}
                  >
                    <a href={item.src} target="_image" title={item.src}>
                      image
                      <img height="64" crossOrigin="anonymous" src={item.src} />
                    </a>
                  </Show>
                </td>
                <td
                  class={itemExists(item, 0) ? 'border-black border-[1px]' : ''}
                >
                  {itemValue(item, 0)}
                </td>
                <td
                  class={itemExists(item, 1) ? 'border-black border-[1px]' : ''}
                >
                  {itemValue(item, 1)}
                </td>
                <td
                  class={itemExists(item, 2) ? 'border-black border-[1px]' : ''}
                >
                  {itemValue(item, 2)}
                </td>
                <td
                  class={itemExists(item, 3) ? 'border-black border-[1px]' : ''}
                >
                  {itemValue(item, 3)}
                </td>
                <td
                  class={itemExists(item, 4) ? 'border-black border-[1px]' : ''}
                >
                  {itemValue(item, 4)}
                </td>
                <td
                  class={itemExists(item, 5) ? 'border-black border-[1px]' : ''}
                >
                  {itemValue(item, 5)}
                </td>
                <td
                  class={itemExists(item, 6) ? 'border-black border-[1px]' : ''}
                >
                  {itemValue(item, 6)}
                </td>
                <td
                  class={itemExists(item, 7) ? 'border-black border-[1px]' : ''}
                >
                  {itemValue(item, 7)}
                </td>
                <td
                  class={itemExists(item, 8) ? 'border-black border-[1px]' : ''}
                >
                  {itemValue(item, 8)}
                </td>
                <td
                  class={itemExists(item, 9) ? 'border-black border-[1px]' : ''}
                >
                  {itemValue(item, 9)}
                </td>
                <td
                  class={
                    itemExists(item, 10) ? 'border-black border-[1px]' : ''
                  }
                >
                  {itemValue(item, 10)}
                </td>
                <td
                  class={
                    itemExists(item, 11) ? 'border-black border-[1px]' : ''
                  }
                >
                  {itemValue(item, 11)}
                </td>
                <td
                  class={
                    itemExists(item, 12) ? 'border-black border-[1px]' : ''
                  }
                >
                  {itemValue(item, 12)}
                </td>
                <td
                  class={
                    itemExists(item, 13) ? 'border-black border-[1px]' : ''
                  }
                >
                  {itemValue(item, 13)}
                </td>
                <td
                  class={
                    itemExists(item, 14) ? 'border-black border-[1px]' : ''
                  }
                >
                  {itemValue(item, 14)}
                </td>
                <td
                  class={
                    itemExists(item, 15) ? 'border-black border-[1px]' : ''
                  }
                >
                  {itemValue(item, 15)}
                </td>
                <td
                  class={
                    itemExists(item, 16) ? 'border-black border-[1px]' : ''
                  }
                >
                  {itemValue(item, 16)}
                </td>
                <td
                  class={
                    itemExists(item, 17) ? 'border-black border-[1px]' : ''
                  }
                >
                  {itemValue(item, 17)}
                </td>
                <td
                  class={
                    itemExists(item, 18) ? 'border-black border-[1px]' : ''
                  }
                >
                  {itemValue(item, 18)}
                </td>
                <td
                  class={
                    itemExists(item, 19) ? 'border-black border-[1px]' : ''
                  }
                >
                  {itemValue(item, 19)}
                </td>
                <td
                  class={
                    itemExists(item, 20) ? 'border-black border-[1px]' : ''
                  }
                >
                  {itemValue(item, 20)}
                </td>
              </tr>
            )}
          </For>
        </tbody>
      </table>
    </div>
    // *INDENT-ON*
  );
};

import type { JSX, Component } from 'solid-js';
import { mergeProps, createSignal, onMount } from 'solid-js';

type Component<P = { object }> = (props: P) => JSX.Element;

export class EmojiSVGProps {
  unicode: number;
}
const defaultEmojiSVGProps = {
  unicode: 0x1f603,
} as EmojiSVGProps;

export class DynamicEmojiSVG {
  sigGetUnicode: Function;
  sigSetUnicode: Function;
  emojiSVGProps: EmojiSVGProps;
  emojisvg: unknown;
  link: unknown;
  constructor(props: EmojiSVGProps = {}) {
    this.emojiSVGProps = mergeProps(defaultEmojiSVGProps, props);
    [this.sigGetUnicode, this.sigSetUnicode] = createSignal(
      this.emojiSVGProps.unicode
    );
  }
  public getString(): string {
    return String.fromCodePoint(this.sigGetUnicode());
  }
  public setString(value: string = '') {
    this.sigSetUnicode(value.codePointAt(0));
  }
  public updateCanvas() {
    const emojictx = this.emojisvg.getContext('2d');
    const emojiimg = new Image();
    const e = this;
    emojiimg.onload = function () {
      emojictx.clearRect(0, 0, 192, 192);
      emojictx.drawImage(emojiimg, 0, 0);
      e.link.innerText = e.emojisvg.toDataURL('image/webp', 1);
    };
    /*
     * <text font-size="192px" text-anchor="middle" alignment-baseline="middle" x="320" y="-320">{partition.sigGetImgSticker()}</text>
     */
    emojiimg.src =
      'data:image/svg+xml,<svg viewBox="-124 -124 248 248" width="192" height="192" xmlns="http://www.w3.org/2000/svg"><text font-size="192px" text-anchor="middle" alignment-baseline="middle" x="0" y="8">' +
      this.getString() +
      '</text></svg>';
  }
}

export const EmojiSVGComponent: Component<EmojiSVGProps> = (props) => {
  const emojiSVG = new DynamicEmojiSVG(props);
  onMount(() => {
    emojiSVG.updateCanvas();
  });
  return (
    // *INDENT-OFF*
    <>
      <span>paste emoji character here:</span>
      <input
        class="text-xl"
        type="text"
        value={emojiSVG.getString()}
        size="8"
        onInput={(e) => {
          const value = e.target.value.trim();
          emojiSVG.setString(value);
          emojiSVG.updateCanvas();
        }}
      />
      <div class="flex justify-center">
        <div class="border-black border-[2px]">
          <canvas ref={emojiSVG.emojisvg} width="192" height="192"></canvas>
        </div>
        <textarea ref={emojiSVG.link}></textarea>
      </div>
    </>
    // *INDENT-ON*
  );
};

import { createSignal, For, Show } from 'solid-js';
import { HttpHeader } from '@solidjs/start';
import { DbTableComponent } from '~/components/designer2/DbTableComponent';
import { EmojiSVGComponent } from '~/components/designer2/EmojiSVGComponent';
import { WearshareBandLogo } from '~/components/designer2/IconComponents';
import { PersistentClient } from '~/shared/PersistentClient';
import { CoreUser, UserRole } from '~/shared/CoreUser';
import { searchParam } from '~/components/designer2/BandComponent';
import { LogComponent } from '~/components/designer2/LogComponent';

export enum Tab {
  ProductionQueue,
  ImageLocal,
  ImageServer,
  BandLocal,
  BandServer,
  ImageOperationLocal,
  ImageOperationServer,
  UserLocal,
  UserServer,
  DatabaseInfoLocal,
  DatabaseInfoServer,
  Developer,
  EmojiSVG,
}

export default function Home() {
  const [getTab, setTab] = createSignal(Tab.ImageLocal);
  const [getAuthorized, setAuthorized] = createSignal(false);
  const showDebugConsole = searchParam('console', 'c');
  // remove override once shopify customer number available
  const override = searchParam('override', 'o');
  setAuthorized(override);
  PersistentClient.instance.getUser().then((coreUser) => {
    if (override) {
      setAuthorized(true);
    } else if (coreUser) {
      setAuthorized(coreUser.data.role === UserRole.admin);
    }
  });
  return (
    // *INDENT-OFF*
    <main class="text-center p-4">
      <HttpHeader name="Cross-Origin-Opener-Policy" value="same-origin" />
      <HttpHeader name="Cross-Origin-Resource-Policy" value="cross-origin" />
      <HttpHeader name="Cross-Origin-Embedder-Policy" value="require-corp" />
      <div id="space_for_floating_menubar" class="h-[48px]" />
      <Show
        when={getAuthorized()}
        fallback={
          <span id="adminerror" class="text-red-600 w-[70%] max-w-[512px]">
            Not logged in with administrator rights
          </span>
        }
      >
        <div class="container mx-auto flex flex-wrap">
          <For each={Object.keys(Tab).filter((key) => isNaN(Number(key)))}>
            {(item) => (
              <button
                class={
                  'rounded-t-lg p-2 border-black border-[2px] ' +
                  (getTab() === Tab[item] ? 'bg-wearsharelime' : 'bg-white')
                }
                onClick={() => {
                  setTab(Tab[item]);
                }}
              >
                {item}
              </button>
            )}
          </For>
        </div>
        <Switch>
          <Match when={getTab() === Tab.ProductionQueue}>
            <DbTableComponent
              name="production_queue"
              local="false"
              select="bandid, name, production_stage"
              where=""
              orderBy="created_timestamp"
              join="INNER JOIN wsUser ON wsBand.who_created = wsUser.userid"
              table="band"
            />
          </Match>
          <Match when={getTab() === Tab.ImageLocal}>
            <DbTableComponent name="image" local="true" />
          </Match>
          <Match when={getTab() === Tab.ImageServer}>
            <DbTableComponent name="image" local="false" />
          </Match>
          <Match when={getTab() === Tab.BandLocal}>
            <DbTableComponent name="band" local="true" />
          </Match>
          <Match when={getTab() === Tab.BandServer}>
            <DbTableComponent name="band" local="false" />
          </Match>
          <Match when={getTab() === Tab.ImageOperationLocal}>
            <DbTableComponent name="image_operation" local="true" />
          </Match>
          <Match when={getTab() === Tab.ImageOperationServer}>
            <DbTableComponent name="image_operation" local="false" />
          </Match>
          <Match when={getTab() === Tab.UserLocal}>
            <DbTableComponent name="user" local="true" />
          </Match>
          <Match when={getTab() === Tab.UserServer}>
            <DbTableComponent name="user" local="false" />
          </Match>
          <Match when={getTab() === Tab.DatabaseInfoLocal}>
            <DbTableComponent name="info" local="true" />
          </Match>
          <Match when={getTab() === Tab.DatabaseInfoServer}>
            <DbTableComponent name="info" local="false" />
          </Match>
          <Match when={getTab() === Tab.Developer}>
            <button
              class={
                'rounded-lg p-2 border-black border-[2px] bg-wearsharelime'
              }
              title="Reset the Local Sqlite3 database and image cache"
              onClick={() => {
                if (PersistentClient.instance) {
                  // only client side
                  PersistentClient.instance.resetLocalAll();
                }
              }}
            >
              Reset Local Db
            </button>
            <button
              class={
                'rounded-lg p-2 border-black border-[2px] bg-wearsharelime'
              }
              title="Reset the Server D1 Sqlite3 database"
              onClick={() => {
                if (PersistentClient.instance) {
                  // only client side
                  PersistentClient.instance.resetServerAll();
                }
              }}
            >
              Reset Server Db
            </button>
          </Match>
          <Match when={getTab() === Tab.EmojiSVG}>
            <EmojiSVGComponent />
          </Match>
        </Switch>
      </Show>
      <center>
        <a href="https://shop.wearshare.com/" title="WEARSHARE HOME">
          <WearshareBandLogo height="64" />
        </a>
      </center>
      <br />
      <Show when={showDebugConsole === true}>
        <LogComponent level="5" nlines="2048" />
      </Show>
    </main>
    // *INDENT-ON*
  );
}
